@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'leaflet.fullscreen/Control.FullScreen.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */
.map-container {
  height: 500px; /* Ajusta la altura según lo que necesites */
  width: 100%;
}

.clustering-map-container {
  height: 600px; /* Ajusta la altura aquí para el mapa de clustering */
  width: 100%;
  margin-top: 1rem; /* Añade un margen si es necesario */
}

.svg-white {
  filter: invert(100%);
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

